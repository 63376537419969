<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code>
          <b-col xl="4">
            <label>الوثيقة</label>
            <b-form-input class="" v-model="documentName" />
          </b-col>
          <br /><br />
          <b-row>
            <b-col xl="2">
              <b-button variant="purple" @click="updateDocument"
                ><span>حفظ</span></b-button
              ></b-col
            >
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from "bootstrap-vue";
export default {
  name: "",

  components: {
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      documentName: "",
    };
  },

  created() {
    // this.getWeak();
    this.getdocument();
  },

  methods: {
    getdocument() {
      this.$http.get("/api/v1/document_types").then((res) => {
      //  console.log(res);
        let documents = res.data.data;
        documents.forEach((ele) => {
          if (ele.id == this.$route.params.id) {
            this.documentName = ele.name;
          }
        });
      });
    },
    updateDocument() {
      let data = {
        name: this.documentName,
      };
      let url = `/api/v1/document_types/${this.$route.params.id}`;
      this.$http.put(url, data).then((res) => {
        //console.log(res);
        this.$swal({
          title: "تم التعديل بنجاح",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    },
    //
  },
};
</script>

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

label {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  font-size: 20px;
}
.align-left {
  margin-left: 750px;
}
.but {
  top: 40px;
}
</style>
